"use strict";

// Class definition
var SigninGeneral = function () {
    // Elements
    var form;
    var submitButton;
    var validator;
    var isSubmited = false;

    const { SConfirm, SAlert } = require("../../helpers/alert");
    const { errorResponse, successResponse } = require('../../helpers/axios');
    const { getUrlParam } = require("../../helpers/url")

    // Handle form
    var handleValidation = function (e) {
        // Init form validation rules. For more info check the FormValidation plugin's official documentation:https://formvalidation.io/
        validator = FormValidation.formValidation(
            form,
            {
                fields: {
                    'email': {
                        validators: {
                            regexp: {
                                regexp: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                message: _('El correo electrónico no es válido'),
                            },
                            notEmpty: {
                                message: _('Ingrese su correo electrónico')
                            },
                            blank: {}
                        }
                    },
                    'password': {
                        validators: {
                            notEmpty: {
                                message: _('Ingrese su contraseña')
                            },
                            blank: {}
                        }
                    }
                },
                plugins: {
                    trigger: new FormValidation.plugins.Trigger(),
                    bootstrap: new FormValidation.plugins.Bootstrap5({
                        rowSelector: '.fv-row',
                        eleInvalidClass: '',  // comment to enable invalid state icons
                        eleValidClass: '' // comment to enable valid state icons
                    })
                }
            }
        );
    }

    var handleSubmitAjax = function (e) {
        // Handle form submit
        submitButton.addEventListener('click', function (e) {
            // Prevent button default action
            e.preventDefault();
            isSubmited = true;

            // Validate form
            validator.validate().then(function (status) {
                if (status == 'Valid') {

                    submitButton.setAttribute('data-kt-indicator', 'on');
                    submitButton.disabled = true;

                    // Check axios library docs: https://axios-http.com/docs/intro 
                    axios.post('/auth/sign-in', {
                        email: form.querySelector('[name="email"]').value,
                        password: form.querySelector('[name="password"]').value
                    }).then(function (response) {

                        let finalFunction = () => {
                            form.querySelector('[name="email"]').value = "";
                            form.querySelector('[name="password"]').value = "";


                            let hasNext = false;
                            if (getUrlParam('next')) {
                                let ctr = 'https://localhost';
                                let nxt = new URL(getUrlParam('next'), ctr);
                                if (nxt.origin == ctr && nxt.href.startsWith(ctr)) {
                                    hasNext = true;
                                    window.location.replace(nxt.pathname + nxt.search);
                                }
                            }

                            if (hasNext === false) {
                                const redirectUrl = form.getAttribute('data-kt-redirect-url');
                                if (redirectUrl) {
                                    location.href = redirectUrl;
                                }
                            }
                        };

                        return successResponse(response, finalFunction);

                    }).catch(function (error) {

                        let errorsWrapper = null;
                        let finalFunction = () => {
                            // form.reset();  // reset form                    
                            // passwordMeter.reset();  // reset password meter                                
                            submitButton.removeAttribute('data-kt-indicator');
                            submitButton.disabled = false;
                        };

                        return errorResponse(error, errorsWrapper, finalFunction, validator);

                    });
                }
            });
        });

        // listen password change
        form.querySelector('[name="password"]').addEventListener('input', function () {
            if (isSubmited === true) {
                validator.validateField('email').then(function (status) {
                    if (status == 'Invalid') {
                        validator.updateFieldStatus('email', 'Valid');
                    }
                });
            }
        });

    }

    // Public functions
    return {
        // Initialization
        init: function () {

            form = document.querySelector('#sign_in_form');
            submitButton = document.querySelector('#sign_in_submit');

            handleValidation();
            handleSubmitAjax(); // use for ajax submit

            document.onkeydown = function (evt) {
                var keyCode = evt ? (evt.which ? evt.which : evt.keyCode) : event.keyCode;
                if (keyCode == 13) {
                    submitButton.click();
                }
            }

        }
    };
}();

// On document ready
KTUtil.onDOMContentLoaded(function () {
    SigninGeneral.init();
});
