"use strict";

/**
 * @class HELPUrl  base utilize class that privides helper functions in alerts messages
 */
 
 // Class definition
 var HELPUrl = function () {
     return {
         
        /**
         * inserta query value en url 
         * @param string key
         * @param string value 
         */
         insertUrlParam(key, value) {
            let searchParams = new URLSearchParams(window.location.search);
            searchParams.set(key, value);
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.replaceState({ path: newurl }, '', newurl);
        },

        /**
         * remueve key value de url, basado en valor del key 
         * @param string key
         */
        removeUrlParam(key) {
            const url = window.location.href
            var r = new URL(url)
            r.searchParams.delete(key)
            const newUrl = r.href
            window.history.replaceState({ path: newUrl }, '', newUrl)
        },

        /**
         * get value de url, basado en valor del key 
         * @param string key
         */
         getUrlParam(key) {
            const url = window.location.href
            var r = new URL(url)
            return r.searchParams.get(key)
        },
         
     }
 }();
 
 // Webpack support
 if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
     module.exports = HELPUrl;
 }