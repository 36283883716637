"use strict";

const { SConfirm, SAlert } = require("./alert");

export function successResponse(response, finalFunction = null) {

    if (
        Sugar.Object.isObject(response.data) === true
        && Sugar.Object.has(response.data, "success") === true
        && response.data.success === true
    ) {

        if (finalFunction != null && typeof finalFunction === "function") {
            finalFunction();
        }

    } else {
        return Promise.reject(response)
    }

};

export function errorResponse(error, errorsWrapper = null, finalFunction = null, validator = null) {
    // handle error
    console.log(error);
    // let data = error.response.data;
    // let status = error.response.status;
    // console.log(data, status);

    let data = {};
    let status = 422;

    if (
        Sugar.Object.isObject(error.response) === true
        && Sugar.Object.has(error.response, "data") === true
    ) {
        data = error.response.data;
        status = error.response.status;
    }
    else if (
        Sugar.Object.isObject(error) === true
        && Sugar.Object.has(error, "data") === true
    ) {
        data = error.data;
    }

    let message = 'Error al procesar su requerimiento, intente más tarde. Si el problema persiste, avise al administrador.';
    if (
        Sugar.Object.isObject(data) === true
        && Sugar.Object.has(data, "message") === true
    ) {
        message = data.message;
    }

    if (
        Sugar.Object.isObject(data) === true
        && Sugar.Object.has(data, "input") === true
        && data.input != null
        && validator != null
    ) {

        validator
            .updateValidatorOption(data.input, 'blank', 'message', message)
            .updateFieldStatus(data.input, 'Invalid', 'blank');

    } else {

        if (status == 419) {

            message = "Error, la página ha expirado, intente de nuevo"

        }

        if (errorsWrapper || errorsWrapper === false) {
            if (errorsWrapper !== false) {
                KTUtil.setHTML(errorsWrapper.querySelector('span.message'), message);
                if (KTUtil.hasClass(errorsWrapper, 'd-none')) {
                    KTUtil.removeClass(errorsWrapper, 'd-none')
                }
            }
        } else {
            SAlert({
                color: 'warning',
                colorButton: 'primary',
                title: 'Error',
                message: message,
                callback: () => {
                    if (status == 419) {
                        window.location.reload();
                    }
                }
            });
        }
    }

    if (finalFunction != null && typeof finalFunction === "function") {
        finalFunction();
    }

};
